<template>
  <div v-loading = "pageloading">
    <cut-in v-if="Access<4" :font="'自定义版交易组件'"></cut-in>
    <div v-else>
      <div>
        <el-card>
          <div class = "titleFont" >选择要在视频号推广的商品添加并提交微信官方审核，审核通过的商品，方可在视频号中进行展示与推广。</div>
          <div  class = "titleFont">1.部分特殊品类商品标题需要按照规范上传，请仔细阅读，避免审核不通过。商品标题规则：
            <el-button type="text" style="padding:0px"><a href="https://jusnn6k8al.feishu.cn/docs/doccnA0iNYwQZVCMqyAfV9fIfrb" target="_blank">点击查看</a></el-button> 
          </div>
          <div class = "titleFont" style="line-height:24px">2.禁发商品：互联网交易禁发商品和直播场景禁发商品，禁发商品包括但不限于列举的二级类目商品。禁发商品列表：
            <el-button type="text" style="padding:0px"><a href="https://jusnn6k8al.feishu.cn/docs/doccnnhsOECGAoKKR2UabPqvx5d" target="_blank">点击查看</a></el-button>
          </div>
        </el-card>
        <el-card style="margin-top:10px">
          <div class = "flexRow" style="justify-content: space-between;">
            <div class = "flexRow" style="flex-wrap:wrap">
              <div class = "flexRow">
                <div style="line-height:37px" class = "boldFont">关键字：</div>
                <el-input v-model="keywords" style="width:250px" placeholder="商品名称、编码"></el-input>
              </div>
              <div class = "flexRow" style="margin:0px 10px 10px 10px">
                <div style="line-height:37px" class = "boldFont">审核状态：</div>
                <el-select v-model="state" placeholder="请选择审核状态" style="200px">
                  <el-option v-for="(v,i) in statelist" :key="i" :value="v.value" :label="v.label"></el-option>
                </el-select>
              </div>
              <div class = "flexRow">
                <div style="line-height:37px" class = "boldFont">上下架状态：</div>
                <el-select v-model="updown" placeholder="请选择审核状态" style="200px">
                  <el-option v-for="(v,i) in updownlist" :key="i" :value="v.value" :label="v.label"></el-option>
                </el-select>
              </div>
              <div class = "flexRow" style="margin:0px 10px;height:36px">
                <el-button type="primary" @click="query">查询</el-button>
                <el-button type="primary" @click="btnpushShop">添加商品</el-button>
              </div>
            </div>
            <div>
              <el-link href = "https://jusnn6k8al.feishu.cn/docs/doccns288uXMQn5a0pkatveRBpf" type = "info" target="_blank" style="margin-top:10px">
                <i class = "el-icon-document" style="margin-right:5px"></i>视频号商品管理说明
              </el-link>
            </div>
          </div>
          <div style="margin-top:10px">
            <el-table :data="tablelist" v-loading = "tableloading" @selection-change="handleSelectionChange" ref="tablelist">
              <el-table-column type="selection" width="50px"></el-table-column>
              <el-table-column prop="" label="商品" width="300px">
                <template slot-scope="scope">
                  <div class = "flexRow">
                    <div style="width:60px;height:60px">
                      <img :src="scope.row.ImgUrlComplete" style="width:60px;height:60px" alt="">
                    </div>
                    <div class = "flexColumn" style="flex:1;margin-left:5px;justify-content: center;">
                      <div class = "fontHidden" style="line-height:18px;-webkit-line-clamp: 2;">
                        {{scope.row.Name}}
                      </div>
                      <div style="width:100%;margin-top:5px;color:#909399">
                        {{scope.row.ProductNo}}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="" label="橱窗价格" width="150px">
                <template slot="header">
                  <div class = "flexRow">
                    <div>
                      橱窗价格
                    </div>
                    <el-tooltip class="item" popper-class = "tooltipWid" effect="dark" placement="top" style="margin-left:5px"
                      content="已经在微信侧审核通过的最新价格，若首次提审，该价格和商品管理中保存的价格一致；若价格需要更新，请重新提审商品">
                      <i class = "el-icon-warning-outline" style="font-size:16px;line-height:24px"></i>
                    </el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div class = "flexColumn">
                    <div >售价：￥{{scope.row.ProductPrice}}</div>
                    <div style="color:#909399">市场价：￥{{scope.row.OriginPrice}}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="" label="商品类目" width="200px">
                <template slot-scope="scope">
                  <div class = "flexRow">
                    <span v-if="!scope.row.FirstCatId && !scope.row.SecondCatId && !scope.row.ThirdCatId">— —</span>
                    <el-popover v-else placement="top" width="300" trigger="hover"
                     :content="scope.row.FirstCatName+' / '+scope.row.SecondCatName+' / '+scope.row.ThirdCatName">
                      <span  slot="reference" class = "fontHidden shopcategoryFont" style="-webkit-line-clamp:3;line-height:18px;">
                        {{scope.row.FirstCatName}} / {{scope.row.SecondCatName}} / {{scope.row.ThirdCatName}}
                      </span>
                    </el-popover>
                      <div class = "flexRow" @click="changecategory(scope.row)" v-if="scope.row.CheckState != 1"
                      style="line-height:18px;color:rgb(64,158,255);cursor:pointer;align-items:flex-end;margin-left:5px">
                        <buttonPermissions :datas="'liveVideoChangecategory'" style="width:50px;">
                          修改
                        </buttonPermissions>
                      </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="" label="商品路径" width="250px">
                <template slot="header">
                  <div class = "flexRow">
                    <div>
                      商品路径
                    </div>
                    <el-tooltip class="item" popper-class = "tooltipWid" effect="dark" placement="top" style="margin-left:5px"
                      content="客户在视频号场景下，点击商品后进入的页面，默认进入常规商品详情页，支持修改为拼团商品详情页">
                      <i class = "el-icon-warning-outline" style="font-size:16px;line-height:24px"></i>
                    </el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div class = "flexRow" style="align-items:center">
                    <div>
                      <div>
                        {{scope.row.ProductPathTypeValue}}
                      </div>
                      <div v-if="scope.row.ProductPathType==1">
                        <span style="color:#999999" v-if="scope.row.ActivityStartTime">{{scope.row.ActivityStartTime}}开始</span>
                        <span style="color:#f56c6c" v-else>活动不存在，请修改路径</span>
                      </div>
                    </div>
                    <el-popover placement="top" v-model="scope.row.popShow" :ref="'popover'+scope.$index">
                      <div v-loading="poploading">
                        <div class="flexRow" style="align-items:center;margin:10px">
                          <span>商品路径：</span>
                          <el-radio-group v-model="ProductPathType">
                            <el-radio v-for="(v,i) in shopPahtList" :key="i" :label="v.value">{{v.label}}</el-radio>
                          </el-radio-group>
                        </div>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text" @click="scope.row.popShow = false">取消</el-button>
                          <el-button type="primary" size="mini" @click="confirmpathtype(scope.row)">确定</el-button>
                        </div>
                      </div>
                      <el-button type="text" slot="reference" style="margin:0px 0px 0px 10px" v-show="scope.row.CheckState!=1"
                       @click="changePathType(scope)">修改</el-button>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="" label="上下架状态">
                <template slot="header">
                  <div class = "flexRow">
                    <div>
                      上下架状态
                    </div>
                    <el-tooltip class="item" popper-class = "tooltipWid" effect="dark" placement="top" style="margin-left:5px"
                      content="在微信侧审核通过的商品，可操作上下架，商品上架后，可以在视频号主页选择展示该商品">
                      <i class = "el-icon-warning-outline" style="font-size:16px;line-height:24px"></i>
                    </el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.ShelfStatus">— —</div>
                  <div v-else>{{scope.row.ShelfStatusValue}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="CheckStateValue" label="审核状态">
                <template slot="header">
                  <div class = "flexRow">
                    <div>
                      审核状态
                    </div>
                    <el-tooltip class="item" popper-class = "tooltipWid" effect="dark" placement="top" style="margin-left:5px"
                      content="首次在视频号添加商品或修改商品信息并且需要同步更新视频号里的商品信息，需要提交微信官方审核">
                      <i class = "el-icon-warning-outline" style="font-size:16px;line-height:24px"></i>
                    </el-tooltip>
                  </div>
                </template>
                <template slot-scope="scope">
                  <!-- <div v-if="!scope.row.CheckState">
                    — —
                  </div> -->
                  <div>
                    {{scope.row.CheckStateValue}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="FailReason" label="驳回原因">
                <template slot-scope="scope">
                  <div v-if="!scope.row.FailReason||scope.row.CheckState!=3">— —</div>
                  <el-popover v-else placement="top" width="300" trigger="hover" :content="scope.row.FailReason">
                    <div slot="reference" class = "fontHidden" style="-webkit-line-clamp:3;line-height:18px;color:rgb(245,108,108)">
                      {{scope.row.FailReason}}
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="" label="操作">
                <template slot-scope="scope">
                    <!-- 审核状态=未审核、审核驳回、上下架状态=违规下架 -->
                  <buttonPermissions :datas="'liveVideoSubmitShop'" class = "operation" style="margin-right:5px;"
                   v-if="scope.row.CheckState==2||scope.row.CheckState==0||scope.row.CheckState==3||scope.row.ShelfStatus==3">
                    <el-button type="text"
                    @click="submitShop(scope.row)">提审商品</el-button>
                  </buttonPermissions>
                  <!-- 审核状态=审核中 -->
                  <buttonPermissions :datas="'liveVideoUndoaudit'" class = "operation" style="margin-right:5px;" v-if="scope.row.CheckState==1">
                    <el-button type="text" @click="undoaudit(scope.row)">撤销审核</el-button>
                  </buttonPermissions>
                  <!-- 上下架状态=已下架 -->
                  <buttonPermissions :datas="'liveVideoUpframe'" class = "operation" style="margin-right:5px;" v-if="scope.row.ShelfStatus==1">
                    <el-button type="text" @click="upframe(scope.row)">上架</el-button>
                  </buttonPermissions>
                  <!-- 上下架状态=已上架 -->
                  <buttonPermissions :datas="'liveVideoDownframe'" class = "operation" style="margin-right:5px;" v-if="scope.row.ShelfStatus==2">
                    <el-button type="text" @click="downframe(scope.row)">下架</el-button>
                  </buttonPermissions>
                  <buttonPermissions :datas="'liveVideoOncedelete'" class = "operation"  style="margin-right:5px;"  v-if="scope.row.CheckState!=1">
                    <el-button type="text" style = "color:rgb(245,108,108)" @click="oncedelete(scope.row)">删除</el-button>
                  </buttonPermissions>
                </template>
                
              </el-table-column>
            </el-table>
            <div v-if="total" class = "flexRow" style="margin-top:10px;justify-content: space-between;">
              <div class = "flexRow">
                <el-checkbox v-model="allcheck" style="margin:5px 0px 0px 15px" @change="checktablelist">当前页全选</el-checkbox>
                <el-dropdown placement = "top" @command="batchoperation">
                  <el-button size="mini" style="margin-left:20px">批量操作</el-button>
                  <el-dropdown-menu slot="dropdown">
                    <div  style = "display:flex;flex-direction:column">
                      <buttonPermissions :datas="'liveVideoSubmitShop'">
                        <el-dropdown-item command="1">提交审核</el-dropdown-item>
                      </buttonPermissions>
                      <buttonPermissions :datas="'liveVideoUpframe'">
                        <el-dropdown-item command="2">上架</el-dropdown-item>
                      </buttonPermissions>
                      <buttonPermissions :datas="'liveVideoDownframe'">
                        <el-dropdown-item command="3">下架</el-dropdown-item>
                      </buttonPermissions>
                      <buttonPermissions :datas="'liveVideoOncedelete'">
                        <el-dropdown-item command="4">删除</el-dropdown-item>
                      </buttonPermissions>
                      <buttonPermissions :datas="'liveVideoChangecategory'">
                        <el-dropdown-item command="5">设置类目</el-dropdown-item>
                      </buttonPermissions>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div>
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="sizepage"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total">
                </el-pagination>
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <el-dialog title="选择商品" class = "chooseshopStyle" width="70%" :visible.sync="chooseshopshow" @close = "chooseshopclose" v-loading = "chooseshoploading">
        <div style="color:#909399;line-height:21px">
          根据微信官方添加商品的条件，已经提交审核，并且审核状态包含未审核、审核中、审核通过、审核失败的商品，不可重复添加；因此在该列表系统会自动过滤相应商品
        </div>
        <div class = "flexRow" style="margin:10px 0px">
          <div class = "flexRow">
            <div style="line-height:37px" class = "boldFont">关键字：</div>
            <el-input v-model="keywords2" placeholder="商品名称、编码" style="width:200px"></el-input>
          </div>
          <div class = "flexRow" style="margin:0px 10px">
            <div style="line-height:37px" class = "boldFont">销售类型：</div>
            <el-select v-model="classity" style="200px">
              <el-option v-for="(v,i) in classitylist" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
        </div>
        <div class = "flexRow" style="margin:10px 0px">
          <div class = "flexRow">
            <div style="line-height:37px" class = "boldFont">商品分组：</div>
            <el-select v-model="shopgroup" style="200px">
              <el-option :value="null" label="全部"></el-option>
              <el-option v-for="(v,i) in shopgrouplist" :key="i" :value="v.Id" :label="v.GroupName"></el-option>
            </el-select>
          </div>
          <div class = "flexRow" style="margin:0px 10px">
            <div style="line-height:37px" class = "boldFont">商品品牌：</div>
            <el-select v-model="shopbrand"  style="200px">
              <el-option :value="null" label="全部"></el-option>
              <el-option v-for="(v,i) in shopbrandlist" :key="i" :value="v.Id" :label="v.BrandName"></el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="query2">查询</el-button>
        </div>
        <div>
          <el-table :data="tablelist2" v-loading = "tableloading2" @selection-change="handleSelectionChange2" ref="tablelist2"
           max-height="400px" :row-key="getrowkey">
            <el-table-column type="selection" width="50px" :reserve-selection="true"></el-table-column>
            <el-table-column prop="" label="商品">
              <template slot-scope="scope">
                  <div class = "flexRow">
                    <div style="width:60px;height:60px">
                      <img :src="scope.row.ImgUrlComplete" style="width:60px;height:60px" alt="">
                    </div>
                    <div class = "flexColumn" style="margin-left:5px;justify-content:center;height:60px;">
                      <div class = "fontHidden" style="line-height:18px;-webkit-line-clamp: 2;">
                        {{scope.row.Name}}
                      </div>
                    </div>
                  </div>
                </template>
            </el-table-column>
            <el-table-column prop="ProductTypeValue" label="销售类型"></el-table-column>
            <el-table-column prop="" label="价格">
              <template slot-scope="scope">
                <div>￥{{scope.row.ProductPrice}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="Stock" label="库存"></el-table-column>
          </el-table>
        </div>
        <div v-if="total2" class="flexRow" style="justify-content: space-between;margin-top:10px">
          <el-checkbox v-model="allcheck2" style="margin:0px 0px 0px 15px" @change="checktablelist2">当前页全选</el-checkbox>
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage2"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total2">
          </el-pagination>
        </div>
        <div class = "flexRow"  style="justify-content:center;margin-top:10px">
          <div style="line-height:37px;margin-right:10px">已选中<span style="color:#F56C6C;">{{checklist2.length}}</span>件</div>
          <el-button type="primary" style="width:90px" @click="confirmchoose">确定</el-button>
        </div>
      </el-dialog>
      <!-- 选择类目 -->
      <el-dialog  :title="categoryTitle" :visible.sync="categoryshow" width="700px" @close = "categoryclose" v-loading = "categoryloading">
        <el-form :model="categoryruleForm" :rules="categoryrules" ref="categoryruleForm">
          <el-form-item prop="category" :rules="categoryrules.category" label="选择类目:" label-width="100px">
            <div class = "flexRow">
              <div v-if="categorylist&&categorylist.length">
                <!-- categoryruleForm.category数据清空之后依旧会有默认选项  所以跟着dialog显示隐藏的时候生成销毁组件 -->
                <el-cascader v-if="categoryshow" style="width:300px" filterable v-model="categoryruleForm.category"
                 :options="categorylist" @change="handleChange" popper-class = "cascaderOptClass"
                placeholder="请选择已经审核通过的类目"></el-cascader>
              </div>
              <!-- //如果没有审核通过的类目  则在使用select下拉框中插入插槽 cascader插槽插不进去-->
              <div v-else>
                <el-select v-model="nolist" style="width:300px" placeholder="请选择已经审核通过的类目">
                  <template slot="empty">
                    <div style="color:#909399;padding:10px;width:300px;line-height:18px">
                      没有已经审核通过的类目，请先前往添加类目并提交微信审核通过。
                      <el-button type="text" @click="jumplink(1)" style="padding:0px">去添加类目>>></el-button>
                    </div>
                  </template>
                </el-select>
              </div>
              <el-button type="text" style="margin-left:10px" @click="jumplink(1)">添加类目</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div class = "diaLog-buttonBox">
          <el-button @click="categoryclose">取消</el-button>
          <el-button type="primary" @click="submitFormcategory('categoryruleForm')">确定</el-button>
        </div>
      </el-dialog>
      <!-- 提审进度 上下架  删除 -->
      <el-dialog title="提示" :visible.sync="auditscheduleshow" width="700px" :close-on-press-escape ="false" 
        :close-on-click-modal ="false"  @closed = "dialogclosed">
        <div class = "flexRow">
          <i class = "el-icon-warning" style="color:#E6A23C;font-size:24px;margin-right:10px;line-height:24px"></i>
          <div style="line-height:24px">{{schedulemsg.text}}</div>
        </div>
        <template slot="footer">
          <el-progress :text-inside="true" :stroke-width="24" :percentage="schedulemsg.plan" status="success" stroke-linecap="square"></el-progress>
        </template>
      </el-dialog>

      <!-- 失败列表 -->
      <el-dialog title="失败列表" :visible.sync="failureshow" @closed = "dialogclosed">
        <el-table :data="failuretablelist" max-height = "500px">
          <el-table-column prop="" label="商品" width="450px">
            <template slot-scope="scope">
                  <div class = "flexRow">
                    <div style="width:60px;height:60px">
                      <img :src="scope.row.ImgUrlComplete" style="width:60px;height:60px" alt="">
                    </div>
                    <div class = "flexColumn" style="flex:1;margin-left:5px;justify-content: center;">
                      <div class = "fontHidden" style="line-height:18px;-webkit-line-clamp: 2;">
                         {{scope.row.Name}}
                      </div>
                    </div>
                  </div>
                </template>
          </el-table-column>
          <el-table-column prop="" label="失败原因" width="450px">
            <template slot-scope="scope">
              <div class = "flexColumn" style="flex:1;margin-left:5px;justify-content: center;">
                <div style="line-height:18px">
                  {{scope.row.FailReason}}
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;text-align:center;margin-top:30px">
          <el-button @click="failureshow = false">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import cutIn from "./components/cutIn"
import config from "@/config"
import{
  videolivevideoliveapplyinfo,
  videolivevideoliveproductpagelist,
  videoliveproductpoplist,
  videoliveaddvideoliveproduct,
  videolivesystemvideolivecategorypagelist,
  videoliveeditvideoliveproductcat,
  videosubmitvideoliveproduct,
  videovalitesubmitvideoliveproduct,
  videoputoffvideoliveproduct,
  videoputonvideoliveproduct,
  videovaliteputonvideoliveproduct,
  videolivevideoliveproductdelete,
  videolivevideoliveproductrecallcheck
}from "@/api/sv1.0.0"
import{
  productBrandList,
  productGroupList,
}from "@/api/goods"
import{
  videoLivevideoLiveProductPathTypeSave
}from "@/api/sv3.0.0"
export default {
  components: {
    cutIn,
    buttonPermissions
  },
  data () {
    var validatePass = (rule, value, callback)=>{
      value.map()
      if(!value&&!value.length){
        callback(new Error('请选择类目'))
      }else{
        callback()
      }
    }
    return {
      Access:0,
      pageloading:false,
      imgUrl:config.IMG_BASE,
      keywords:'',
      state:null,
      statelist:[
        {label:'全部',value:null},
        {label:'未审核',value:0},
        {label:'审核中',value:1},
        {label:'审核通过',value:2},
        {label:'审核驳回',value:3},
      ],
      updown:null,
      updownlist:[
        {label:'全部',value:null},
        // {label:'无状态',value:0},
        {label:'已下架',value:1},
        {label:'已上架',value:2},
        {label:'违规下架',value:3},
      ],
      tablelist:[],
      tableloading:false,
      allcheck:false,
      checklist:[],

      currentPage:1,
      sizepage:20,
      total:null,

      chooseshopshow:false,
      chooseshoploading:false,
      keywords2:'',
      classity:null,
      classitylist:[
        {value:null,label:'全部'},
        {value:0,label:'单品销售'},
        {value:1,label:'组合销售'},
      ],
      shopgroup:null,
      shopgrouplist:[],
      shopbrand:null,
      shopbrandlist:[],

      tablelist2:[],
      tableloading2:false,
      allcheck2:false,
      checklist2:[],
      temporarylist:[],
      currentPage2:1,
      sizepage2:10,
      total2:null,
      
      categoryTitle:'修改类目',
      categoryshow:false,
      categoryloading:false,
      categoryruleForm:{
        category:[],
        Id:0,
        num:0,
      },
      categoryrules:{
        category:[
          // { required: true, validator: validatePass, trigger: 'blur' },
          { type: 'array', required: true, message:'请选择类目', trigger: 'blur' },
        ]
      },
      categorylist:[],
      nolist:null,
      auditscheduleshow:false,
      schedulemsg:{
        text:'',
        plan:0,
      },
      failureshow:false,
      failuretablelist:[],
      shopPahtList:[
        {value:0,label:'常规商品详情页'},
        {value:1,label:'拼团商品详情页'},
      ],
      poploading:false,
      ProductPathType:0,
    }
  },
  created () {
    // let list = this.assemble(this.filterarr(arr,1),this.filterarr(arr,2),this.filterarr(arr,3))
    // console.log(this.filterarr(arr,1),1)
    // console.log(this.filterarr(arr,2),2)
    // console.log(this.filterarr(arr,3),3)
        // console.log(list)
    // console.log(arr)
    this.getvideolivevideoliveapplyinfo()
  },
  methods: {
    changePathType(scope){
      this.ProductPathType = JSON.parse(JSON.stringify(scope.row.ProductPathType))
    },
    async confirmpathtype(row){
      try{
        this.poploading = true
        let res = await videoLivevideoLiveProductPathTypeSave({
          VideoLiveProductId:await row.Id,
          ProductPathType:this.ProductPathType
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.poploading = false
      }
    },
    async getvideolivevideoliveapplyinfo(){
      this.pageloading = true
      let result = await videolivevideoliveapplyinfo()
      if(result.IsSuccess){
        // console.log(result.Result)
        
        this.Access = result.Result.State
        // console.log(this.Access)
        if(this.Access>=4){
          this.gettablelist()
          this.getvideolivesystemvideolivecategorypagelist()
        }
      }
      this.pageloading = false
    },
    getrowkey(row){
      return row.Id
    },
    dialogclosed(){
      this.gettablelist()
    },
    //单个提审商品
    submitShop(e){
      // console.log(e)
      if(e.CheckState == 1 || !e.ThirdCatId){
        this.$alert('根据微信官方要求，未设置类目的商品或已经在审核中的商品不可提交审核，请重新选择商品','提示',{
              confirmButtonText:'好的',
              type:'warning'
            }).then(()=>{})
      }else{
        const h = this.$createElement;
            this.$msgbox({
              title: '提审须知',
              message: h('div', [
                h('p',{style:'line-height:24px'}, '1.商品提交至微信官方审核，审核通过后，将更新视频号-商品橱窗内的数据，并且上架；客户可看到最新的商品信息'),
                h('p',{style:'line-height:24px'}, '2.微信官方审核时间是09:00-24:00，审核时效半天，若长时间未审核通过，请撤消审核重新提审')
              ]),
              showCancelButton: true,
              confirmButtonText: '继续提审',
              cancelButtonText: '关闭',
              type:'warning',
            }).then(()=>{
              this.tovideosubmitvideoliveproduct(e)
              // this.auditscheduleshow = true
              // this.schedulemsg.plan = 0
              // this.schedulemsg.text = "商品提审中，在提审结束前，请勿刷新页面或关闭该弹框，否则会导致部分商品提审失败"
            }).catch(()=>{})
      }
    },
    async tovideosubmitvideoliveproduct(e){
      // console.log(e)
      let id = await e.Id
      this.pageloading = true
      try{
        let data = {
          VideoLiveProductId:id,
          // noError:true
        }
        let result = await videosubmitvideoliveproduct(data)
        if(result.Result.IsSuccess){
          // console.log(result,2323)
          this.$message.success('提审成功')
        }else{
          this.failuretablelist = [{
            ImgUrlComplete:e.ImgUrlComplete,
            Name:e.Name,
            FailReason:result.Result.FailReason
          }]
          this.failureshow = true
        }
      }finally{
        this.pageloading = false
        this.gettablelist()
      }
    },
    //撤销审核
    undoaudit(e){
      this.$confirm('商品撤消审核后，不影响已经审核通过的数据；你可以重新提交审核，是否确认撤消？','提示',{
        confirmButtonText: '确认撤销',
        cancelButtonText: '关闭',
        type:'warning',
      }).then(()=>{
        this.tovideolivevideoliveproductrecallcheck(e)
      }).catch(()=>{})
    },
    async tovideolivevideoliveproductrecallcheck(e){
      this.pageloading = true
      try{
        let data = {
          VideoLiveProductId:await e.Id
        }
        let result = await videolivevideoliveproductrecallcheck(data)
        if(result.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.pageloading = false
      }
    },
    //单个上架商品
    upframe(e){
      // console.log(e,52)
      if(e.ShelfStatus == 2){
        this.$alert('首次添加/提审的商品、微信风控/违规下架商品，不可上架，请重新选择商品','提示',{
              confirmButtonText:'好的',
              type:'warning'
            }).then(()=>{})
      }else{
        const h = this.$createElement;
            this.$msgbox({
              title: '上架须知',
              message: h('p',{style:'line-height:24px'}, '商品上架后，客户可在视频号主页-商品列表，看到相关商品，也可点击商品进入商城小程序购买'),
              showCancelButton: true,
              confirmButtonText: '继续上架',
              cancelButtonText: '关闭',
              type:'warning',
            }).then(()=>{
              this.tovideoputonvideoliveproduct(e)
            }).catch(()=>{})
      }
    },
    async tovideoputonvideoliveproduct(e){
      let id = await e.Id
      this.pageloading = true
      try{
        let data = {
          VideoLiveProductId:id,
          // noError:true
        }
        let result = await videoputonvideoliveproduct(data)
        if(result.Result.IsSuccess){
          this.$message.success('操作成功')
        }else{
          this.failuretablelist = [{
            ImgUrlComplete:e.ImgUrlComplete,
            Name:e.Name,
            FailReason:result.Result.FailReason
          }]
          this.failureshow = true
        }
      }finally{
        this.pageloading = false
        this.gettablelist()
      }
    },
    //单个下架
    downframe(e){
      const h = this.$createElement;
            this.$msgbox({
              title: '下架须知',
              message: h('p',{style:'line-height:24px'}, '商品下架，仅从视频号主页-商品列表中移除商品，不影响小程序商城内正常销售与展示；下架商品若需要重新展示在视频号内，可重新操作上架'),
              showCancelButton: true,
              confirmButtonText: '继续下架',
              cancelButtonText: '关闭',
              type:'warning',
            }).then(()=>{
              this.tovideoputoffvideoliveproduct(e)
              // this.auditscheduleshow = true
              // this.schedulemsg.plan = 0
              // this.schedulemsg.text = "商品下架中，在下架结束前，请勿刷新页面或关闭该弹框，否则会导致部分商品下架失败"
            }).catch(()=>{})
    },
    async tovideoputoffvideoliveproduct(e){
      this.pageloading = true
      try{
        let id = await e.Id
        let data = {
          VideoLiveProductId:id,
        }
        let result = await videoputoffvideoliveproduct(data)
        // console.log(1)
        if(result.IsSuccess){
          this.$message.success('操作成功')
        }
      }finally{
        this.pageloading = false
        this.gettablelist()
      }
    },
    //单个删除
    oncedelete(e){
      const h = this.$createElement;
      this.$msgbox({
        title: '删除须知',
        message: h('p',{style:'line-height:24px'}, '商品删除，仅从视频号中删除商品，不影响小程序商城内正常销售与展示；删除后，不可恢复，若需要重新在视频号中展示，需要重新添加商品并提交审核'),
        showCancelButton: true,
        confirmButtonText: '继续删除',
        cancelButtonText: '关闭',
        type:'warning',
      }).then(()=>{
        this.tovideolivevideoliveproductdelete(e)
        //这里直接调用删除接口
      }).catch(()=>{})
    },
    //删除
    async tovideolivevideoliveproductdelete(e){
      this.pageloading = true
      try{
        let data = {
          VideoLiveProductId:await e.Id
        }
        let result = await videolivevideoliveproductdelete(data)
        // console.log(result)
        if(result.IsSuccess){
          this.$message.success('操作成功')
        }
      }finally{
        this.pageloading = false
        this.gettablelist()
      }
    },
    //获取类目列表
    async getvideolivesystemvideolivecategorypagelist(){
      let data = {
        State:2,
        Skip:0,
        Take:9999
      }
      let result = await videolivesystemvideolivecategorypagelist(data)
      if(result.IsSuccess){
        // console.log(result.Result.Results)
        this.categorylist =  this.assemble(this.filterarr(result.Result.Results,1),this.filterarr(result.Result.Results,2),this.filterarr(result.Result.Results,3))
        // console.log(this.categorylist)
      }
    },
    //筛选类目
    filterarr(arr,num){
      let key = ''
      if(num == 1){
        key = 'FirstCatId'
      }else if(num==2){
        key = 'SecondCatId'
      }else if(num==3){
        key = 'ThirdCatId'
      }
      let newarr = []
      arr.forEach((v)=>{
        // console.log(v)
        // if(v.FirstCatId&&v.SecondCatId){
          let a = newarr.find((x)=>{
            return x[key] == v[key]
          })
          // console.log(a.SecondCatId)
          if(!a){
            newarr.push(v)
          }
        // }
      })
      return newarr
    },
    assemble(arr1,arr2,arr3){
      // console.log(arr2)
      let newarr = arr1.map((v)=>{
        let children2 = []
        arr2.map((x)=>{
          let children3 = []
          arr3.map((m)=>{
            if(m.SecondCatId == x.SecondCatId){
              let data2 = {
                value:m.ThirdCatId,
                label:m.ThirdCatName,
                product_qualification:m.ProductQualification,
                product_qualification_type:m.ProductQualificationType,
                qualification:m.Qualification,
                qualification_type:m.QualificationType,
                Id:m.Id
              }
              children3.push(data2)
            }
          })
          if(x.FirstCatId == v.FirstCatId){
            let data1 =  {
              value:x.SecondCatId,
              label:x.SecondCatName,
              children:children3
            }
            children2.push(data1)
          }
        })
        return {
          value:v.FirstCatId,
          label:v.FirstCatName,
          children:children2
        }
      })
      return newarr
    },


    categoryclose(){
      this.categoryshow = false
      this.categoryruleForm.category = []
      this.$refs.categoryruleForm.resetFields()
    },

    submitFormcategory(formName){
      // console.log(this.categoryruleForm.category)
      // this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       this.tovideoliveeditvideoliveproductcat()
      //     } else {
      //       this.$message.error('请选择类目')
      //       return false;
      //     }
      //   });
      if(this.categoryruleForm.category[2] != 0 && this.categoryruleForm.category && this.categoryruleForm.category.length){
        this.tovideoliveeditvideoliveproductcat()
      }else{
        this.$message.error('请选择类目')
      }
    },
    //商品类目修改
    async tovideoliveeditvideoliveproductcat(){
      this.categoryloading = true
      try{
          let a = this.categorylist.find((v)=>{
            return v.value == this.categoryruleForm.category[0]
          })
          let b = a.children.find((v)=>{
            return v.value == this.categoryruleForm.category[1]
          })
          let c = b.children.find((v)=>{
            return v.value == this.categoryruleForm.category[2]
          })
        let data = {
          VideoProductIdList:'',
          VideoProductCategoryId:c.Id
        }
        if(this.categoryruleForm.num==1){
          data.VideoProductIdList = [this.categoryruleForm.Id]
        }else if(this.categoryruleForm.num==2){
          data.VideoProductIdList = this.checklist.map((v)=>{
            return v.Id
          })
        }
        // console.log(data)
        let result = await videoliveeditvideoliveproductcat(data)
            
        if(result.IsSuccess){
            this.categoryshow = false
            this.$alert('类目设置成功，请在列表上提交商品审核','提示',{
              confirmButtonText:'好的',
              type:'success'
            }).then(()=>{})
        }
      }finally{
        this.categoryshow = false
        this.categoryloading = false
        this.gettablelist()
      }
    },
    //批量操作
    batchoperation(e){
      // console.log(e)
      if(!this.checklist.length){
        this.$message.error('请先选择商品')
      }else{
        if(e == 5){
          this.categoryTitle  = "批量设置类目"
          this.categoryruleForm.num = 2
          this.categoryshow = true
        }else{
          if(e == 1){
            this.audit()
          }else if(e == 2){
            this.upshelves()
          }else if(e == 3){
            this.downshelves()
          }else if(e == 4){
            this.deleteshop()
          }
        }
      }
    },
    //批量提审
    audit(){
      let iscan = this.checklist.every((v)=>{
            return v.CheckState != 1 && v.ThirdCatId
          })
          // console.log(iscan)
          if(!iscan){
            this.$alert('根据微信官方要求，未设置类目的商品或已经在审核中的商品不可提交审核，请重新选择商品','提示',{
              confirmButtonText:'好的',
              type:'warning'
            }).then(()=>{})
          }else{
            this.tovideovalitesubmitvideoliveproduct()
          }
    },
    //校验批量提审
    async tovideovalitesubmitvideoliveproduct(){
      this.pageloading = true
      try{
        
        let data = {
          VideoLiveProductIdList:[],
          noError:true
        }
        data.VideoLiveProductIdList = this.checklist.map((v)=>{
          return v.Id
        })
        // console.log(data)
        let result = await videovalitesubmitvideoliveproduct(data)
        if(result.IsSuccess){
          const h = this.$createElement;
            this.$msgbox({
              title: '提审须知',
              message: h('div', [
                h('p',{style:'line-height:24px'}, '1.商品提交至微信官方审核，审核通过后，将更新视频号-商品橱窗内的数据，并且上架；客户可看到最新的商品信息'),
                h('p',{style:'line-height:24px'}, '2.微信官方审核时间是09:00-24:00，审核时效半天，若长时间未审核通过，请撤消审核重新提审')
              ]),
              showCancelButton: true,
              confirmButtonText: '继续提审',
              cancelButtonText: '关闭',
              type:'warning',
            }).then(()=>{
              this.nextRemand()
            }).catch(()=>{})
        }else{
          this.$alert('根据微信官方要求，未设置类目的商品或已经在审核中的商品不可提交审核，请重新选择商品','提示',{
              confirmButtonText:'好的',
              type:'warning'
            }).then(()=>{})
        }
      }finally{
        this.pageloading = false
        // this.gettablelist()
      }
    },
    async nextRemand(){
      let failuretablelist = []
      this.auditscheduleshow = true
      this.schedulemsg.plan = 0
      this.schedulemsg.text = "商品提审中，在提审结束前，请勿刷新页面或关闭该弹框，否则会导致部分商品提审失败"
      let num = 0
      let gap = Math.round(100/this.checklist.length)
        // console.log(v)
        try{
          for(let v of this.checklist){
            let data = {
              VideoLiveProductId:v.Id,
              noError:true
            }
            // console.log(data)
            let result = await videosubmitvideoliveproduct(data)
            // console.log(result.Result)
            if(result.IsSuccess){
                if(!result.Result.IsSuccess){
                  let list = {
                    ImgUrlComplete:v.ImgUrlComplete,
                    Name:v.Name,
                    FailReason:result.Result.FailReason
                  }
                  failuretablelist.push(list)
                }
            }else{
              this.$message.error(result.Message)
            }
          num ++
          this.schedulemsg.plan = num*gap>100?100:num*gap
          }
        }finally{//不管成功失败 都更新进度
          if(num >= this.checklist.length){
            if(failuretablelist&&failuretablelist.length){//有失败
              this.failuretablelist = failuretablelist
              this.auditscheduleshow = false
              this.failureshow = true
            }else{
              this.$message.success('操作成功')
              this.auditscheduleshow = false
            }
          }
        }
      
      
    },
    //批量上架
    upshelves(){
      // console.log(this.checklist)
      //若选中的商品首次提审，或上下架状态=违规下架，操作失败
      let iscan = this.checklist.every((v)=>{
            return v.CheckState != 0 && v.ShelfStatus != 3 
          })
          if(!iscan){
            this.$alert('首次添加/提审的商品、微信风控/违规下架商品，不可上架，请重新选择商品','提示',{
              confirmButtonText:'好的',
              type:'warning'
            }).then(()=>{})
          }else{
            this.batchcheckup()
          }
    },
    async batchcheckup(){
      this.pageloading = true
      try{
        let data = {
          VideoLiveProductIdList:this.checklist.map((v)=>{
            return v.Id
          }),
          noError:true
        }
        let result = await videovaliteputonvideoliveproduct(data)
        // console.log(result)
        if(result.IsSuccess){
            const h = this.$createElement;
            this.$msgbox({
              title: '上架须知',
              message: h('p',{style:'line-height:24px'}, '商品上架后，客户可在视频号主页-商品列表，看到相关商品，也可点击商品进入商城小程序购买'),
              showCancelButton: true,
              confirmButtonText: '继续上架',
              cancelButtonText: '关闭',
              type:'warning',
            }).then(()=>{
              this.batchup()
            }).catch(()=>{})
        }else{
          this.$alert('首次添加/提审的商品、微信风控/违规下架商品，不可上架，请重新选择商品','提示',{
              confirmButtonText:'好的',
              type:'warning'
            }).then(()=>{})
        }
      }finally{
        this.pageloading = false
      }
    },
    async batchup(){
      let failuretablelist = []
      this.auditscheduleshow = true
      this.schedulemsg.plan = 0
      this.schedulemsg.text = "商品上架中，在上架结束前，请勿刷新页面或关闭该弹框，否则会导致部分商品上架失败"
      let num = 0
      let plannum = Math.round(100/this.checklist.length)
        try{
          for(let v of this.checklist){
            let data = {
              VideoLiveProductId:v.Id,
              noError:true
            }
            let result = await videoputonvideoliveproduct(data)
            // console.log(result)
            if(result.IsSuccess){
              if(result.Result&&!result.Result.IsSuccess){
                let list = {
                  ImgUrlComplete:v.ImgUrlComplete,
                  Name:v.Name,
                  FailReason:result.Result.FailReason
                }
                failuretablelist.push(list)
              }
            }else{
              this.$message.error(result.Message)
            }
            num++
            this.schedulemsg.plan = num*plannum>100?100:num*plannum
          }
        }finally{
          if(num >= this.checklist.length){
            if(failuretablelist&&failuretablelist.length){
              this.failuretablelist = failuretablelist
              this.failureshow = true
              this.auditscheduleshow = false
            }else{
              this.$message.success('操作成功')
              this.auditscheduleshow = false
            }
          }
        }
    },
    //批量下架
    downshelves(){
            const h = this.$createElement;
            this.$msgbox({
              title: '下架须知',
              message: h('p',{style:'line-height:24px'}, '商品下架，仅从视频号主页-商品列表中移除商品，不影响小程序商城内正常销售与展示；下架商品若需要重新展示在视频号内，可重新操作上架'),
              showCancelButton: true,
              confirmButtonText: '继续下架',
              cancelButtonText: '关闭',
              type:'warning',
            }).then(()=>{
              this.batchdown()
            }).catch(()=>{})
    },
    async batchdown(){
      // console.log(this.checklist,963)
      this.auditscheduleshow = true
      this.schedulemsg.plan = 0
      this.schedulemsg.text = "商品下架中，在下架结束前，请勿刷新页面或关闭该弹框，否则会导致部分商品下架失败"
      let failuretablelist = []
      let num = 0
      let plannum = Math.round(100/this.checklist.length)
        try{
          for(let v of this.checklist){
            let data = {
              VideoLiveProductId:v.Id,
              noError:true
            }
            let result = await videoputoffvideoliveproduct(data)
            // if(result.IsSuccess){
            //     // if(!result.Result.IsSuccess){
            //     //   let list = {
            //     //     ImgUrlComplete:v.ImgUrlComplete,
            //     //     Name:v.Name,
            //     //     FailReason:result.Result.FailReason
            //     //   }
            //     //   failuretablelist.push(list)
            //     // }
            // }else{
            //   this.$message.error(result.Message)
            // }
            num++
            this.schedulemsg.plan = num*plannum>100?100:num*plannum
          }
        }finally{
          if(num >= this.checklist.length){
        //     if(failuretablelist&&failuretablelist.length){//有失败
        //       this.failuretablelist = failuretablelist
        //       this.auditscheduleshow = false
        //       this.failureshow = true
        //     }else{
              this.$message.success('操作成功')
              this.auditscheduleshow = false
        //     }
          }
        }
    },
    //删除
    deleteshop(){
      const h = this.$createElement;
            this.$msgbox({
              title: '删除须知',
              message: h('p',{style:'line-height:24px'}, '商品删除，仅从视频号中删除商品，不影响小程序商城内正常销售与展示；删除后，不可恢复，若需要重新在视频号中展示，需要重新添加商品并提交审核'),
              showCancelButton: true,
              confirmButtonText: '继续删除',
              cancelButtonText: '关闭',
              type:'warning',
            }).then(()=>{
              this.batchdelete()
              //这里直接调用删除接口
            }).catch(()=>{})
    },
    async batchdelete(){
      // this.pageloading = true
      this.auditscheduleshow = true
      this.schedulemsg.plan = 0
      this.schedulemsg.text = "商品删除中，在删除结束前，请勿刷新页面或关闭该弹框，否则会导致部分商品删除失败"
      let num = 0
      let plannum = Math.round(100/this.checklist.length)
      let failuretablelist = []
        try{
          for(let v of  this.checklist){
        // console.log(v)
              let data = {
                VideoLiveProductId:v.Id,
                noError:true
              }
              let result = await videolivevideoliveproductdelete(data)
              console.log(result)
              if(!result.IsSuccess){
                let list = {
                  ImgUrlComplete:v.ImgUrlComplete,
                  Name:v.Name,
                  FailReason:result.Message
                }
                failuretablelist.push(list)
              }
              num++
              this.schedulemsg.plan = num*plannum>100?100:num*plannum
          }
        }finally{
          // console.log(num)
          if(num >= this.checklist.length){
            if(failuretablelist&&failuretablelist.length){
              this.failuretablelist = failuretablelist
              this.failureshow = true
              this.auditscheduleshow = false
            }else{
              this.$message.success('操作成功')
              this.auditscheduleshow = false
            }
          }
            // this.pageloading = false
            // this.gettablelist()
        }
    },
    jumplink(e){
      let route = ''
      if(e == 1){
        route = '/setting/liveVideo/categorymanagement'
      }
      this.$router.push({
        path:route
      })
    },
    //点击   修改选择类目
    changecategory(e){
      this.categoryTitle = '修改类目'
      // this.getvideolivevideolivewxappcatlist()
      // console.log(e)
      this.categoryruleForm.category = [e.FirstCatId,e.SecondCatId,e.ThirdCatId]
      this.categoryruleForm.Id = e.Id
      this.categoryruleForm.num = 1 //单个修改
      this.categoryshow = true
    },
    //选择类目
    handleChange(e){
      this.categoryruleForm.category = e
      // console.log(e,1)
    },
    //选择商品弹窗关闭
    chooseshopclose(){
      this.allcheck2 = false
      this.checklist2 = []
      this.currentPage2 = 1
      this.sizepage2 = 10
      this.total2 = null
      this.keywords2 = ''
      this.classity = null
      this.shopgroup = null
      this.shopbrand = null
      this.$refs.tablelist2.clearSelection()
    },
    //选择商品确定
    confirmchoose(){
      if(!this.checklist2.length){
        this.$message.error('请先选择商品')
      }else{
        //这里调接口 等返回成功了在进行下一步
        this.tovideoliveaddvideoliveproduct()
        // this.chooseshopshow = false
        // this.$alert('商品已经添加至列表，请在列表上完善商品类目或提交审核','提示',{
        //     confirmButtonText: '好的',
        //     type:'success'
        // }).then(()=>{
        //   this.gettablelist()
        // }).catch(()=>{})
      }
    },
    async tovideoliveaddvideoliveproduct(){
      this.chooseshoploading = true
      try{
        let arr = this.checklist2.map((v)=>{
          return v.Id
        })
      // console.log(arr)
        let data = {
          ProductIdList:arr
        }
        let result = await videoliveaddvideoliveproduct(data)
        if(result.IsSuccess){
          this.chooseshopshow = false
          this.$alert('商品已经添加至列表，请在列表上完善商品类目或提交审核','提示',{
              confirmButtonText: '好的',
              type:'success'
          }).then(()=>{
            this.gettablelist()
          }).catch(()=>{})
        }
      }finally{
        this.chooseshoploading = false
      }
    },
    handleSelectionChange2(e){
      this.checklist2 = e
      let arr1 = this.checklist2.map((v)=>{
        return v.Id
      })
      let arr2 = this.tablelist2.map((v)=>{
        return v.Id
      })
      // console.log(this.isContained(arr1,arr2))
      // console.log(arr1,arr2)
      this.allcheck2 = this.isContained(arr1,arr2)
    },
    //判断a是否包含b
    isContained(a, b){
      if(!(a instanceof Array) || !(b instanceof Array)) return false;
      if(a.length < b.length) return false;
      var aStr = a.toString();
          for(var i = 0, len = b.length; i < len; i++){
            if(aStr.indexOf(b[i]) == -1) return false;
          }
          return true;
    },
    checktablelist2(){
      this.$refs.tablelist2.toggleAllSelection();
    },
    handleSizeChange2(e){
      this.currentPage2 = 1
      this.sizepage2 = e
      this.gettablelist2()
    },
    handleCurrentChange2(e){
      this.currentPage2 = e
      this.gettablelist2()
    },
    //当前页全选
    checktablelist(e){
      this.$refs.tablelist.toggleAllSelection();
    },
    //选择表格列表
    handleSelectionChange(e){
      // console.log(e)
      this.checklist = e
      this.allcheck = this.checklist.length == this.tablelist.length
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    btnpushShop(){
      this.chooseshopshow = true
      this.getproductGroupList()
      this.getproductBrandList()
      this.gettablelist2()
    },
    query2(){
      this.currentPage2 = 1
      this.gettablelist2()
    },
    //分组
    async getproductGroupList(){
      let result = await productGroupList()
      if(result.IsSuccess){
          // console.log(result.Result)
          this.shopgrouplist = result.Result
        }
    },
    //品牌
    async getproductBrandList(){
      let result = await productBrandList()
        if(result.IsSuccess){
          // console.log(result.Result)
          this.shopbrandlist = result.Result
        }
    },
    async gettablelist2(){
      this.tableloading2 = true
      try{
        let data = {
          Keywords:this.keywords2,
          ProductType:this.classity,
          ProductGroupId:this.shopgroup,
          ProductBrandId:this.shopbrand,
          Skip:(this.currentPage2-1)*this.sizepage2,
          Take:this.sizepage2
        }
        let result = await videoliveproductpoplist(data)
        if(result.IsSuccess){
          this.tablelist2 = result.Result.Results
          this.total2 = result.Result.Total


          
          let arr1 = this.checklist2.map((v)=>{
            return v.Id
          })
          let arr2 = this.tablelist2.map((v)=>{
            return v.Id
          })
          this.allcheck2 = this.isContained(arr1,arr2)
        }
      }finally{
        this.tableloading2 = false
      }
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          Keywords:this.keywords,
          CheckState:this.state,
          ShelfStatus:this.updown,
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage,
        }
        let result = await videolivevideoliveproductpagelist(data)
        // console.log(result,123)
        if(result.IsSuccess){
          // console.log(result.Result)
          this.tablelist = result.Result.Results.map(v=>{
            v.popShow = false
            return v
          })
          this.total = result.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    
  }
}
</script>

<style lang = "less" scoped>
  *,html,body{
    font-size:14px;
  }
  .boldFont{
    font-weight: bold;
    color: #606266;
  }
  .flexRow{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* align-items: center; */
  }
  .flexColumn{
    display: flex;
    flex-direction: column;
  }
  .fontHidden{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .diaLog-buttonBox{
    width:100%;
    margin-top:30px;
    text-align: right;
  }
  ::v-deep .el-dialog__footer{
    padding:0px
  }
  ::v-deep .el-progress-bar__inner{
    border-radius:0px
  }
  ::v-deep .el-progress-bar__outer{
    border-radius:0px
  }
  .operation ::v-deep .el-button{
    text-align: left;
  }
  .titleFont{
    font-size: 14px;
    color: #606266;
    line-height:24px
  }
  .border{
    border:1px solid black
  }
  .chooseshopStyle ::v-deep .el-dialog__header{
    padding:20px 20px 0px
  }
  .chooseshopStyle ::v-deep .el-dialog__body{
    padding: 20px;
  }
</style>
<style>
  .cascaderOptClass .el-cascader-panel .el-cascader-node__label{
    /* border:1px solid black !important; */
    max-width:130px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .tooltipWid {
    max-width: 300px !important;
  }
</style>